import React from 'react'
const Address = () => {
    return (
        <div className='address'>
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d1040.3022345492827!2d49.86116193553353!3d40.4005796556897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1str!2saz!4v1717706285207!5m2!1str!2saz"
                height="300px"
                id="address"
                width="100%"
                allowFullScreen={true}
                style={{border: 'none'}}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade" />

                
        </div>
    )
}

export default Address
